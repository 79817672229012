<template>
    <div class="examinantion-main">
        <div class="head">
            <div class="head-title">
                <p class="txt"></p>
                <p class="icon-text">{{$t('optional.msgTitle')}}</p>
            </div>
            <p class="subtitle">
                <span>{{$t('optional.subtitle')}}</span>
            </p>
        </div>
        <div class="main-center">
            
        </div>
        <div class="main-select">
            <i class="icon-img"></i>
            <p class="select-title">{{$t("optional.appointmentMake")}}</p>
            <p class="placeholder-txt">{{$t('optional.physucalGender')}}</p>
            <div class="select-btn">
                <div class="btn-item" @click="goPath(1)">
                    <i class="icon"></i>
                    {{$t('optional.men')}}
                </div>
                <div class="btn-item girl" @click="goPath(2)">
                    <i class="icon"></i>
                    {{$t('optional.women')}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    },
    created(){
        document.title = this.$t("optional.name")
    },
    methods:{
        goPath(type){
            this.$router.push({
                path:"/shop/suppermarket",
                query:{
                    sexType:type
                }
            })
        }
    }
}
</script>

<style lang="scss">

.examinantion-main{
    height: max-content;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    background: url("~@/assets/img/examinantion-main-index-back.png") no-repeat;
    background-size: cover;
    padding: 0.6rem;
    overflow: auto;
    box-sizing: border-box;
    .head{
        padding: 0 0.2rem 0 0.8rem;
        position: relative;
        padding-top: 1.2rem;
        .head-title{
            display: flex;
            position: relative;
            z-index: 9;
            .txt{
                font-size: 2.08rem;
                font-family: HuXiaoBo_KuHei;
                font-weight: 400;
                font-style: italic;
                // color: #FFFFFF;
                text-shadow: 0px 0.04rem 0.04rem rgba(44, 87, 210, 0.49);
                background: url("~@/assets/img/exanubabtuib-title.png") no-repeat center;
                background-size: 100% auto;
                background-position-y:00.5rem;
                width: 9.32rem;
                height: 3.5rem;
            }
            .icon-text{
                margin-left: 0.5rem;
                background: url("~@/assets/img/head-msg-back.png") no-repeat;
                background-size: 100% 100%;
                font-size: 0.48rem;
                font-family: PingFang SC;
                font-weight: 600;
                font-style: italic;
                color: #1C3BB4;
                width: 1.96rem;
                padding: 0.6rem 0.58rem 0.8rem 0.58rem;
                transform: rotate(15deg);
                text-align: center;
                height: -webkit-max-content;
                height: -moz-max-content;
                height: max-content;
                line-height: 0.52rem;
                margin-top: -0.1rem;
            }
        }
        .subtitle{
            font-size: 0.52rem;
            font-family: PingFang SC;
            font-weight: 300;
            font-style: italic;
            color: #B4F7FF;
            padding: 0.24rem;
            margin-top: -0.6rem;
            span{
                padding-left: 0.1rem;
                position: relative;
                z-index: 9;
            }
            &::after{
                content: " ";
                background: url("~@/assets/img/subtitle-back.png") no-repeat;
                background-size: 100% auto;
                position: absolute;
                left: -0.48rem;
                width: 100%;
                height: 6rem;
                top: 2.6rem;
                z-index: 1;
            }
        }
    }
    .main-center{
        height: 12.2rem;
        width: 100%;
        padding: 0.6rem;
        margin-left: -0.6rem;
        background: url("~@/assets/img/tjcs-main-back.png") no-repeat;
        background-size:auto 100%;
        background-position-x: 100%;
        margin-top: -1.2rem;
    }
    .main-select{
        height: 8rem;
        // margin: 0.6rem;
        background: #FFFFFF;
        border-radius: 0.6rem;
        position: relative;
        box-sizing: border-box;
        padding-top: 0.8rem;
        text-align: center;
        .icon-img{
            background: url("~@/assets/img/person-icon.png") no-repeat;
            position: absolute;
            height: 9.8rem;
            width: 5rem;
            display: block;
            background-size: 100% 100%;
            bottom: 6.1rem;
            left: 1.2rem;
        }
        .select-title{
            margin-top: 0.9rem;
            font-size: 0.72rem;
            font-family: PingFang SC;
            font-weight: 600;
            color: #303133;
        }
        .placeholder-txt{
            // margin-top: 0.3rem;
            font-size: 0.6rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #909999;
        }
        .select-btn{
            padding: 0 0.74rem;
            display: flex;
            margin-top: 0.8rem;
            justify-content: space-between;
            .btn-item{
                font-size: 0.64rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #1D93FE;
                display: flex;
                align-items: center;
                padding: 0.6rem 0.8rem;
                background: url("~@/assets/img/sex-manbtn-back.png") no-repeat;
                background-size: 100% 100%;
                background-position-y: .1rem;
                .icon{
                    height: 0.68rem;
                    display: block;
                    width: 0.68rem;
                    background: url("~@/assets/img/sex-man.png") no-repeat;
                    background-size: 100%;
                    margin-right: 0.1rem;
                }
            }
            .girl{
                background: url("~@/assets/img/sex-girlbtn-back.png") no-repeat;
                background-size: 100% 100%;
                background-position-y: .1rem;
                color: #FF3486;
                .icon{
                    background: url("~@/assets/img/sex-girl.png") no-repeat;
                    background-size: 100%;
                }
            }
        }
    }
}
</style>